import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
// Jen
import { APICore } from '../api/apiCore';
interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
    roles: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps) => {
    const api = new APICore();
    //console.log("roles antes:",roles);
    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (api.isUserAuthenticated() === false) {
                    return (
                        <Redirect
                            to={{
                                pathname: '/auth/login',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                }

                const loggedInUser = api.getLoggedInUser();

                //No encuentro roles / como se maneja
                // check if route is restricted by role
                if (roles && roles.indexOf(loggedInUser.idRol) === -1) {
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: '/home' }} />;
                }

                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;